import React from 'react';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import { emptyObject, isNotInMode, WidgetsMode } from '../../../common/sgwt-widgets-utils';
import { INavigateAs, ISgwtAccountCenterUser, SidePanelScreen } from '../shared/sgwt-account-center.types';
import { MyServicesLink } from './MyServices/MyServicesLink';
import { SgmNotification } from './Notification/notification.types';
import { useSgmNotification } from './Notification/SgmNotificationContext';
import { PartialIncident } from './PartialIncident/PartialIncident';
import { UserButton } from './parts';
import { EnvironmentTag, NavigateAsTag } from './Tags';
import { UserInfo } from './UserInfo';

interface ConnectedToolbarProps extends ITranslatorProps {
  authentication?: string;
  availableLanguages: string[];
  changePasswordLink: string | null;
  displaySidePanel: (screen: SidePanelScreen | null, notifications?: SgmNotification[]) => void;
  enableNotifications: boolean;
  emitEvent: (name: string, detail: any) => void;
  environment: string;
  environmentTag?: string;
  hideNavigateAs: () => void;
  hideServicesLink?: boolean;
  language: string;
  logDebug: (...messages: any[]) => void;
  navigateAs: INavigateAs;
  onSignOut: () => void;
  platform: 'desktop' | 'mobile' | 'ios' | 'android';
  producerCode?: string;
  selectUserOnBehalf: () => void;
  showNavigateAs: () => void;
  showNavigateAsModal: boolean;
  sidePanelDisplayed: SidePanelScreen | null;
  signOutLink: string | null;
  user: ISgwtAccountCenterUser;
  widgetMode: WidgetsMode | null;
}

export const ConnectedToolbar = (props: ConnectedToolbarProps) => {
  const { unreadNotifications, incidents, incidentStatus, cleanIncidents } = useSgmNotification();
  const hideMyServicesLink = isNotInMode(props.widgetMode, ['sg-markets', 'b2b2c']) && props.hideServicesLink;

  return (
    <div className="d-flex position-relative align-items-stretch">
      {/* My Services button */}
      {!hideMyServicesLink && (
        <MyServicesLink
          {...props}
          forceMyServicesDirectLink={false}
          onButtonClick={() => props.displaySidePanel('my-services')}
        />
      )}

      {/* User button */}
      <UserButton
        onClick={() => props.displaySidePanel('user')}
        notificationsCount={unreadNotifications}
        title={props.translator.translate('button.myAccount')}
      />

      {/* User Information */}
      <UserInfo {...props} />

      <div className="sgwt-account-center-tags">
        {incidents.length > 0 && (
          <PartialIncident
            {...props}
            incidents={incidents}
            incidentStatus={incidentStatus}
            cleanIncidents={cleanIncidents}
          />
        )}
        {props.environmentTag && <EnvironmentTag environment={props.environmentTag} />}
        {!emptyObject(props.navigateAs.asUser) && (
          <NavigateAsTag {...props} selectUserOnBehalf={props.showNavigateAs} />
        )}
      </div>
    </div>
  );
};
