import React, { useState } from 'react';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import { SvgIcon } from '../../../../common/components/SvgIcon';
import { registerAccountCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import { SgmNotification, SgmNotificationKind } from './notification.types';
import { useSgmNotification } from './SgmNotificationContext';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const locales: Record<string, any> = {
  de: require('date-fns/locale/de'),
  en: require('date-fns/locale/en'),
  es: require('date-fns/locale/es'),
  fr: require('date-fns/locale/fr'),
  jp: require('date-fns/locale/ja'),
  ja: require('date-fns/locale/ja'),
  ru: require('date-fns/locale/ru'),
  zh: require('date-fns/locale/zh_cn'),
};

interface NotificationContentProps {
  notification: SgmNotification;
  kind: SgmNotificationKind;
  notificationsCount: number;
}

const NotificationContent = ({ notification, kind, notificationsCount }: NotificationContentProps) => {
  const warning =
    kind === 'action' ? (
      <span className="badge badge-discreet-warning badge-sm rounded-pill fw-bold font-weight-bold">!</span>
    ) : null;
  if (notification.category) {
    return (
      <>
        <div className="d-flex align-items-end justify-content-between">
          <span>
            {notificationsCount > 1 && (
              <span className="badge badge-discreet-primary badge-sm rounded-pill me-1 mr-1">{notificationsCount}</span>
            )}
            <span className="font-weight-semibold fw-semibold mt-1">{notification.category}</span>
          </span>
          {warning}
        </div>
        <p className="my-0">{notification.payload}</p>
      </>
    );
  }
  return (
    <div className="d-flex align-items-start justify-content-between">
      <p className="my-0">{notification.payload}</p>
      {warning}
    </div>
  );
};

interface NotificationBodyProps extends NotificationContentProps {
  language: string;
  onDismiss: (id: number) => void;
}

const NotificationBody = ({ language, notification, kind, notificationsCount, onDismiss }: NotificationBodyProps) => {
  const clickNotif = () => {
    registerAccountCenterEvent(`notifications.click-${notification.producer.universe.toLowerCase()}-notification`, {
      producer: notification.producer.name,
    });
  };

  const dismissNotification = (evt: Event) => {
    evt.preventDefault(); // do not trigger the navigation to the notification link...
    onDismiss(notification.id);
  };

  const sg = notification.producer.universe === 'societeGenerale';

  return (
    <div className={`list-group-item list-group-item-action px-4 ${notificationsCount > 1 ? 'pb-1' : ''}`}>
      <a
        className="sgwt-one-notification text-primary"
        style={notification.url ? {} : { cursor: 'default' }}
        id={notification.id.toString()}
        href={notification.url || '#'}
        target={notification.url ? '_blank' : ''}
        onClick={clickNotif}
        rel="noreferrer"
      >
        <div className="d-flex align-items-center text-small mb-2">
          <abbr
            className={`sgwt-account-center-initials-micro-box ${sg ? 'bg-lvl3 text-secondary' : 'border'} d-flex align-items-center justify-content-center text-decoration-none`}
            title={notification.producer.name}
            style={{ cursor: 'default' }}
          >
            <span aria-hidden="true" className="text-uppercase fw-semibold font-weight-semibold fs-12 text-small">
              {notification.producer.initials}
            </span>
          </abbr>
          <div className="font-weight-medium fw-medium fs-12 text-small line-height-1 flex-grow-1 mx-2">
            {notification.producer.name}
          </div>
          <div className="text-small fs-6 text-secondary">
            {distanceInWordsToNow(notification.createdDate, {
              locale: locales[language],
            })}
          </div>
          <button
            className="btn btn-flat-secondary btn-sm btn-icon ms-2 ml-2"
            onClick={(evt) => dismissNotification(evt.nativeEvent)}
          >
            <SvgIcon type="close" />
          </button>
        </div>
        <NotificationContent kind={kind} notification={notification} notificationsCount={notificationsCount} />
      </a>
    </div>
  );
};

interface NotificationItemProps extends ITranslatorProps {
  language: string;
  notifications: SgmNotification[];
}

export const NotificationItem = ({ language, translator, notifications }: NotificationItemProps) => {
  const { dismissNotifications } = useSgmNotification();
  const firstNotif = notifications[0];
  // When we group notifications, we want to display the kind of the most important notification,
  // even if the `kind=action` is not on the first notification of the group.
  const kindForGroup = notifications.some((n) => n.kind === 'action') ? 'action' : 'info';
  const [expanded, setExpanded] = useState<boolean>(false);
  const switchExpanded = () => setExpanded(!expanded);

  const dismissNotif = (fromNotifId: number) => {
    // If the grouped notifications are expanded, we dismiss only the notification that was clicked.
    // If they are still collapsed, we dismiss the whole group.
    const groupIds = notifications.map((notif: SgmNotification) => notif.id);
    dismissNotifications(expanded ? [fromNotifId] : groupIds);
  };

  return (
    <div className="flex-column align-items-start border-bottom border-opacity-40 border-alpha-xl">
      {expanded ? (
        notifications.map((notif: SgmNotification) => (
          <NotificationBody
            key={notif.id}
            language={language}
            notification={notif}
            kind={notif.kind}
            notificationsCount={1}
            onDismiss={dismissNotif}
          />
        ))
      ) : (
        <NotificationBody
          language={language}
          notification={firstNotif}
          kind={kindForGroup}
          notificationsCount={notifications.length}
          onDismiss={dismissNotif}
        />
      )}

      {notifications.length > 1 && (
        <div className="mx-4 mb-2">
          <button
            className="btn btn-icon-text btn-icon-start btn-link btn-sm btn-block justify-content-start text-left"
            onClick={switchExpanded}
          >
            <SvgIcon type={expanded ? 'expand_less' : 'expand_more'} height={16} width={16} />
            {translator.translate(`notifications.show${expanded ? 'Less' : 'More'}`)}
          </button>
        </div>
      )}
    </div>
  );
};
