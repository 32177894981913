import React, { Component } from 'react';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { SvgIcon } from '../../../../common/components/SvgIcon';
import { IWidgetConfigurationContext, WidgetConfigurationContext } from '../../../../common/configuration';
import { registerAccountCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import { MY_SERVICES_DOMAIN, MY_SERVICES_LINKS } from '../../shared/sgwt-account-center.endpoints';
import { ISgwtAccountCenterUser } from '../../shared/sgwt-account-center.types';
import { isUserPartOfBeta } from './beta-users';
import {
  IBookmarkedService,
  IMyServiceSearchResults,
  IMyServiceSearchSgmService,
  IMyServiceSearchSgService,
  IServiceVisibility,
} from './my-services.types';
import { PersonsAndCompaniesList } from './PersonsAndCompaniesList';
import { ServiceMiniCard } from './ServiceMiniCard';

const MAX_SGM_RESULTS = 4;
const MAX_NON_SGM_RESULTS = 4;

interface IMyServicesSearchPanelProps extends ITranslatorProps {
  bookmarkedServices: IBookmarkedService[];
  bookmarkUpdated: () => void;
  environment: 'homologation' | 'production';
  formUrl: string;
  language: string;
  onClick: (visibility?: IServiceVisibility | null) => void;
  searchResults: IMyServiceSearchResults | null;
  searchValue: string;
  user: ISgwtAccountCenterUser | null;
  widgetConfiguration: WidgetConfiguration;
}

interface IMyServicesSearchPanelState {
  bookmarkIds: Record<string, number>;
}

export class MyServicesSearchPanel extends Component<IMyServicesSearchPanelProps, IMyServicesSearchPanelState> {
  static contextType = WidgetConfigurationContext;
  private widgetConfiguration: WidgetConfiguration;

  constructor(props: IMyServicesSearchPanelProps, context: IWidgetConfigurationContext) {
    super(props);
    this.widgetConfiguration = context!.widgetConfiguration;
    const bookmarkIds: Record<string, number> = {};
    this.props.bookmarkedServices.forEach((bookmark: IBookmarkedService) => {
      bookmarkIds[bookmark.ReferenceId] = bookmark.Id;
    });
    this.state = { bookmarkIds };
  }

  private onBookmarkUpdated = (referenceId: string, bookmarkId: number | null) => {
    const ids = { ...this.state.bookmarkIds };
    if (bookmarkId) {
      // Bookmark added
      ids[referenceId] = bookmarkId;
    } else {
      // Bookmark removed
      delete ids[referenceId];
    }
    this.setState({ bookmarkIds: ids });
    this.props.bookmarkUpdated();
  };

  render() {
    if (this.props.searchResults === null) {
      return null;
    }
    const countSGM = this.props.searchResults.sgm.items.length;
    const countNonSGM = this.props.searchResults.sg.items.length;
    const myServicesPage = MY_SERVICES_LINKS[this.props.environment];
    const { translator } = this.props;
    const showPersonsAndCompanies = isUserPartOfBeta(this.props.user, this.widgetConfiguration);

    return (
      <div className="mb-4">
        {countSGM + countNonSGM === 0 && (
          <div className="mt-2 mb-3 text-secondary">{translator.translate('myServices.notFound')}</div>
        )}
        {countSGM > 0 && (
          <div>
            <p className="mb-0 pb-2 mx-n4 px-4 text-secondary font-weight-normal fw-normal">
              {translator.translate('myServices.foundInSGMarkets')}
            </p>
            <ul className="list-group mx-n4 border-bottom border-top">
              {this.props.searchResults.sgm.items
                .slice(0, MAX_SGM_RESULTS)
                .map((s: IMyServiceSearchSgmService, index: number) => (
                  <ServiceMiniCard
                    key={`service-filtered-${s.referenceId}`}
                    bookmarkId={this.state.bookmarkIds[s.referenceId] || null}
                    bookmarkUpdated={this.onBookmarkUpdated}
                    environment={this.props.environment}
                    firstInList={index === 0}
                    isSgmService={true}
                    language={this.props.language}
                    myServicesPage={myServicesPage}
                    onClick={this.props.onClick}
                    service={s}
                    translator={this.props.translator}
                  />
                ))}
            </ul>
            {countSGM > MAX_SGM_RESULTS && (
              <SeeAllResults
                count={this.props.searchResults.sgm.searchMetaPageData.totalCount}
                environment={this.props.environment}
                searchValue={this.props.searchValue}
                translator={this.props.translator}
                type={'sgmarkets'}
              />
            )}
          </div>
        )}
        {countNonSGM > 0 && (
          <div>
            <p className="mt-3 mb-0 pb-2 mx-n4 px-4 text-secondary font-weight-normal fw-normal">
              {translator.translate('myServices.foundInSG')}
            </p>
            <ul className="list-group mx-n4 border-bottom border-top">
              {this.props.searchResults.sg.items
                .slice(0, MAX_NON_SGM_RESULTS)
                .map((s: IMyServiceSearchSgService, index: number) => (
                  <ServiceMiniCard
                    key={`service-filtered-${s.referenceId}`}
                    bookmarkId={this.state.bookmarkIds[s.referenceId] || null}
                    bookmarkUpdated={this.onBookmarkUpdated}
                    environment={this.props.environment}
                    firstInList={index === 0}
                    isSgmService={false}
                    language={this.props.language}
                    myServicesPage={myServicesPage}
                    onClick={this.props.onClick}
                    service={s}
                    translator={this.props.translator}
                  />
                ))}
            </ul>
            {countNonSGM > MAX_NON_SGM_RESULTS && (
              <SeeAllResults
                count={this.props.searchResults.sg.searchMetaPageData.totalCount}
                environment={this.props.environment}
                searchValue={this.props.searchValue}
                translator={this.props.translator}
                type={'societe'}
              />
            )}
          </div>
        )}

        {/* Only for internal users... */}
        {!!this.props.formUrl && (
          <p className="text-secondary mt-3">
            {translator.translate('myServices.addInternalService.start')}
            <a
              href={this.props.formUrl}
              className="text-link"
              target="_blank"
              rel="noreferrer"
              onClick={() => registerAccountCenterEvent('my-services.click-add-your-own-link')}
            >
              {translator.translate('myServices.addInternalService.link')}
            </a>
            {translator.translate('myServices.addInternalService.end')}
          </p>
        )}

        {showPersonsAndCompanies && this.props.searchResults.person.items.length > 0 && (
          <PersonsAndCompaniesList
            type="person"
            environment={this.props.environment}
            items={this.props.searchResults.person.items}
            searchValue={this.props.searchValue}
            totalCount={this.props.searchResults.person.searchMetaPageData.totalCount}
            translator={translator}
          />
        )}
        {showPersonsAndCompanies && this.props.searchResults.company.items.length > 0 && (
          <PersonsAndCompaniesList
            type="company"
            environment={this.props.environment}
            items={this.props.searchResults.company.items}
            searchValue={this.props.searchValue}
            totalCount={this.props.searchResults.company.searchMetaPageData.totalCount}
            translator={translator}
          />
        )}
      </div>
    );
  }
}

interface ISeeAllResultsProps extends ITranslatorProps {
  count: number;
  environment: 'homologation' | 'production';
  searchValue: string;
  type: string;
}

export const SeeAllResults = ({ count, environment, searchValue, translator, type }: ISeeAllResultsProps) => (
  <div className="text-right text-end pt-2 mx-n4 px-4">
    <a
      href={`${MY_SERVICES_DOMAIN[environment]}/search/${searchValue}/${type}`}
      target="_blank"
      onClick={() => registerAccountCenterEvent('my-services.click-see-all-results', { type })}
      rel="noreferrer"
    >
      {translator.translate('myServices.seeResults', { count: `${count}` })}
      <SvgIcon type="arrow_forward" className="icon ml-1 mt-n1" width={18} height={18} />
    </a>
  </div>
);
