import React from 'react';
import { registerAccountCenterEvent } from '../../../common/monitoring';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import { WidgetsMode } from '../../../common/sgwt-widgets-utils';
import { MY_SERVICES_LINKS } from '../shared/sgwt-account-center.endpoints';
import { isMyServicesLinkDisplayed } from './MyServices/my-services.api';
import { LanguageSelectorDropdown } from './LanguageSelectorDropdown';
import { MyServicesButton } from './parts';
import { EnvironmentTag } from './Tags';

interface IDisconnectedToolbarProps extends ITranslatorProps {
  availableLanguages: string[];
  emitEvent: (name: string, detail: any) => void;
  environment: string;
  environmentTag?: string;
  hideServicesLink?: boolean;
  language: string;
  onLanguageChanged: (newLanguage: string) => void;
  onSignIn: () => void;
  showLanguagesSelection?: boolean;
  showSignInButton?: boolean;
  widgetMode: WidgetsMode | null;
}

export const DisconnectedToolbar: React.FC<IDisconnectedToolbarProps> = (props: IDisconnectedToolbarProps) => {
  const signIn = () => {
    props.onSignIn();
    registerAccountCenterEvent('user-toolbar.click-sign-in');
  };

  const myServicesLinkClick = () => registerAccountCenterEvent('my-services.click-link');

  const { availableLanguages, environmentTag } = props;
  const showMyServicesLink = isMyServicesLinkDisplayed(props.widgetMode, props.hideServicesLink);

  return (
    <div className="d-flex">
      {/* My Services button */}
      {showMyServicesLink && (
        <MyServicesButton
          directLink={true}
          myServicesLink={MY_SERVICES_LINKS[props.environment]}
          title={props.translator.translate('button.myServices')}
          onClick={myServicesLinkClick}
        />
      )}
      {/* Languages selection */}
      {props.showLanguagesSelection && availableLanguages.length > 1 && <LanguageSelectorDropdown {...props} />}
      {/* Sign-in button */}
      {props.showSignInButton && (
        <button
          className="btn btn-flat-secondary btn-lg ml-1 ms-1 text-nowrap sgwt-account-center-sign-in-button"
          onClick={signIn}
        >
          {props.translator.translate('signIn')}
        </button>
      )}

      {/* Environment tag */}
      <div className="sgwt-account-center-tags">
        {environmentTag && <EnvironmentTag environment={environmentTag} />}
      </div>
    </div>
  );
};
