import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { registerAccountCenterEvent } from '../../../../common/monitoring';
import { EventDetails } from '../../sgwt-account-center';
import { IPartialIncident, PARTIAL_INCIDENT_CLOSED, PARTIAL_INCIDENT_OPEN } from '../PartialIncident/PartialIncident';
import { SgmNotification } from './notification.types';
import { markNotificationsAsRead } from './notifications.utils';

type EventEmitter = (name: string, detail: EventDetails) => void;

export interface SgmNotificationReducerState {
  notifications: SgmNotification[];
  incidents: IPartialIncident[];
  incidentStatus: number | null;
  emitEvent: EventEmitter;
  widgetConfiguration: WidgetConfiguration;
  producerCode?: string;
}

type SgmNotificationReducerAction =
  | { type: 'notifications.set'; notifications: SgmNotification[] }
  | { type: 'notifications.add'; notification: SgmNotification }
  | { type: 'notifications.dismiss'; ids: number[] }
  | { type: 'notifications.dismiss-all' }
  | { type: 'incidents.set'; incidents: IPartialIncident[] }
  | { type: 'incidents.add'; incident: IPartialIncident }
  | { type: 'incidents.clean' };

/**
 * This Reducer is used by the <SgmNotificationContext> to manage the state
 * of the Notifications and Partial Incidents.
 */
export function sgmNotificationsReducer(
  currentState: SgmNotificationReducerState,
  action: SgmNotificationReducerAction,
): SgmNotificationReducerState {
  switch (action.type) {
    // -----------------------------------------------------------------------
    // --- SGM NOTIFICATIONS
    // -----------------------------------------------------------------------
    // Set all Notifications (called after initial fetch).
    case 'notifications.set': {
      // Exclude expired notifications
      const notifications = action.notifications.filter((notif: SgmNotification) => !notif.isExpired);
      const unreadNotifications = notifications.filter((notif: SgmNotification) => !notif.isRead);
      if (unreadNotifications.length > 0) {
        currentState.emitEvent('notifications-unread-notifications', {
          notifications: unreadNotifications.map((unread: SgmNotification) => ({
            createdDate: unread.createdDate,
            id: unread.id,
            payload: unread.payload,
            producerCode: unread.producer.code,
            category: unread.category,
            url: unread.url,
          })),
        });
      }
      return {
        ...currentState,
        notifications,
      };
    }

    // Add a new Notification (live notification from SignalR Hub)
    case 'notifications.add': {
      const { notification } = action;
      let newNotifs: SgmNotification[] = [];
      if (notification.isExpired || notification.isRead) {
        // If the new notification is expired or read, we remove it locally if it exists.
        newNotifs = currentState.notifications.filter((n: SgmNotification) => n.id !== notification.id);
      } else if (currentState.notifications.every((n: SgmNotification) => n.id !== notification.id)) {
        // Add only if the notification does not already exist
        newNotifs = [...currentState.notifications, notification];
        registerAccountCenterEvent('notifications.new', { notificationId: notification.id });
        currentState.emitEvent('notifications-new-notification', {
          notification: {
            createdDate: notification.createdDate,
            id: notification.id,
            payload: notification.payload,
            producerCode: notification.producer.code,
            category: notification.category,
            url: notification.url,
          },
        });
      }

      return {
        ...currentState,
        notifications: newNotifs,
      };
    }

    // Dismiss one or several Notifications
    case 'notifications.dismiss': {
      const { ids } = action;
      registerAccountCenterEvent('notifications.dismiss-notifications', { count: ids.length });
      markNotificationsAsRead(currentState.widgetConfiguration, ids);
      const newNotifs = currentState.notifications.filter((notif: SgmNotification) => !ids.includes(notif.id));

      return {
        ...currentState,
        notifications: newNotifs,
      };
    }

    // Dismiss all Notifications
    case 'notifications.dismiss-all': {
      registerAccountCenterEvent('notifications.dismiss-all');
      const ids = currentState.notifications.map((notif: SgmNotification) => notif.id);
      markNotificationsAsRead(currentState.widgetConfiguration, ids);

      return {
        ...currentState,
        notifications: [],
      };
    }

    // -----------------------------------------------------------------------
    // --- PARTIAL INCIDENTS
    // -----------------------------------------------------------------------

    // Set all Partial Incidents (called after initial fetch).
    case 'incidents.set': {
      return {
        ...currentState,
        incidents: action.incidents.filter((incident) => incident.status !== PARTIAL_INCIDENT_CLOSED),
      };
    }

    // Add a new Partial Incident (live notification from SignalR Hub)
    case 'incidents.add': {
      const { incident } = action;
      if (typeof incident.status === 'number') {
        incident.status = incident.status === 1 ? PARTIAL_INCIDENT_CLOSED : PARTIAL_INCIDENT_OPEN;
      }
      if (typeof incident.targetingType === 'number') {
        incident.targetingType = incident.targetingType === 1 ? 'broadcast' : 'specific';
      }
      if (
        !currentState.producerCode ||
        (incident.targetingType === 'specific' && currentState.producerCode !== incident.producerCode)
      ) {
        return currentState;
      }
      let newIncidents: IPartialIncident[] = [];
      let newIncidentStatus: number | null = null;
      if (!currentState.incidents.some((icdt) => icdt.id === incident.id)) {
        newIncidents = [incident, ...currentState.incidents];
        newIncidentStatus = incident.id;
      } else {
        newIncidents = [...currentState.incidents];
        for (const oneIncident of newIncidents) {
          if (oneIncident.id === incident.id) {
            oneIncident.status = incident.status;
            oneIncident.text = incident.text;
            break;
          }
        }
        newIncidentStatus = incident.status !== PARTIAL_INCIDENT_OPEN ? incident.id : null;
      }
      registerAccountCenterEvent('partial-incidents.new', { incidentId: incident.id });

      currentState.emitEvent('partial-incidents-new-incident', {
        incident: {
          createdDate: incident.createdDate,
          id: incident.id,
          producerCode: incident.producerCode,
          status: incident.status,
          targets: incident.targets,
          targetingType: incident.targetingType,
          text: incident.text,
        },
      });

      return {
        ...currentState,
        incidents: newIncidents,
        incidentStatus: newIncidentStatus,
      };
    }

    // Clean all closed Partial Incidents (once the user has closed the Partial Incidents modal)
    case 'incidents.clean': {
      const incidents = currentState.incidents.filter((incident) => incident.status !== PARTIAL_INCIDENT_CLOSED);
      const incidentStatus =
        currentState.incidentStatus && incidents.some((i) => i.id === currentState.incidentStatus)
          ? currentState.incidentStatus
          : null;
      return {
        ...currentState,
        incidents,
        incidentStatus,
      };
    }

    default:
      return currentState;
  }
}

interface ReducerConfiguration {
  producerCode?: string;
  emitEvent: EventEmitter;
  widgetConfiguration: WidgetConfiguration;
}

export function createInitialState(configuration: ReducerConfiguration): SgmNotificationReducerState {
  return {
    ...configuration,
    notifications: [],
    incidents: [],
    incidentStatus: null,
  };
}
