import React, { useRef, useState } from 'react';
import { registerAccountCenterEvent } from '../../../common/monitoring';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import { LanguagesButton, UserToolbarDropdown } from './parts';

interface LanguageSelectorDropdownProps extends ITranslatorProps {
  availableLanguages: string[];
  emitEvent: (name: string, detail: any) => void;
  language: string;
  onLanguageChanged: (newLanguage: string) => void;
}

export const LanguageSelectorDropdown = ({
  availableLanguages,
  emitEvent,
  language,
  onLanguageChanged,
  translator,
}: LanguageSelectorDropdownProps) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const element = useRef(null);

  const hideDropdown = () => {
    setShowDropdown(false);
    registerAccountCenterEvent('languages-dropdown.close');
    emitEvent('hide-languages-dropdown', {});
  };

  const changeDropdownVisibility = () => {
    if (showDropdown) {
      hideDropdown();
    } else {
      setShowDropdown(true);
      registerAccountCenterEvent('languages-dropdown.open');
      emitEvent('show-languages-dropdown', {});
    }
  };

  const clickLanguageBtn = (lang: string) => {
    registerAccountCenterEvent('languages-dropdown.change-language', { language: lang });
    onLanguageChanged(lang);
  };

  return (
    <div ref={element} className="position-relative">
      <LanguagesButton
        language={language}
        onClick={changeDropdownVisibility}
        title={translator.translate('button.languages')}
      />
      <UserToolbarDropdown
        className="sgwt-account-center-languages"
        rootElement={element?.current || undefined}
        onHide={hideDropdown}
        visible={showDropdown}
      >
        {availableLanguages.map((lang) => {
          const langName = translator.getLanguageName(lang);
          return (
            <button className="dropdown-item" key={`language-${lang}`} onClick={() => clickLanguageBtn(lang)}>
              {langName}
            </button>
          );
        })}
      </UserToolbarDropdown>
    </div>
  );
};
