interface SgmNotificationAction {
  callbackEndpoint: string;
  name: string;
}

export const SgmNotificationUniverses = ['sgMarkets', 'societeGenerale', 'sgef'] as const;
export type SgmNotificationUniverse = (typeof SgmNotificationUniverses)[number];

export interface SgmProducerInfo {
  code: string;
  initials: string;
  internalOnly: boolean;
  name: string;
  universe: SgmNotificationUniverse;
}

export type SgmNotificationKind = 'info' | 'action';

export interface SgmNotification {
  actions: SgmNotificationAction[];
  category?: string | null;
  createdDate: string;
  id: number;
  isClicked: boolean;
  isExpired: boolean;
  isRead: boolean;
  kind: SgmNotificationKind;
  payload: string;
  producer: SgmProducerInfo;
  pushMobileEnabled: boolean;
  url: string;
  userEmail: string;
}

export type ENVIRONMENT = 'homologation' | 'production';

interface ApiPath {
  api: string;
  hubRegistration: string;
  negotiation: string;
  viewAll: string;
}

type SgmNotificationPath = { [key in ENVIRONMENT]: ApiPath };

export const DELAY_RETRY = 20;
export const MAX_RETRY = 10;
export const MAX_VIEW = 50;

export const EMPLOYEE_PORTAL_PRODUCER_CODE = 'bsc_employee_portal';

export const NOTIFICATION_PATH: SgmNotificationPath = {
  homologation: {
    api: 'https://api-s-uat.sgmarkets.com/services/insight/notification/private/v1',
    viewAll: 'https://www-uat.sgmarkets.com/mynotifications',
    // DEV urls:
    // negotiation:
    //   'https://sgmarkets-hub-notification-dev.sgmpinsight.dev.euw.gbis.sg-azure.com/api/v1/Negotiations/notificationhub',
    // registration:
    //   'https://sgmarkets-hub-notification-dev.sgmpinsight.dev.euw.gbis.sg-azure.com/api/v1/Registrations/notificationhub',
    // HOM urls:
    negotiation: 'https://api-z-dev.sgmarkets.com/sgmpinsight-notification-hub-uat/api/v1/Negotiations/notificationhub',
    hubRegistration:
      'https://api-z-dev.sgmarkets.com/sgmpinsight-notification-hub-uat/api/v1/Registrations/notificationhub',
  },
  production: {
    api: 'https://api-s.sgmarkets.com/services/insight/notification/private/v1',
    viewAll: 'https://www.sgmarkets.com/mynotifications/sgm_integration',
    negotiation: 'https://api-z.sgmarkets.com/sgmpinsight-notification-hub/api/v1/Negotiations/notificationhub',
    hubRegistration: 'https://api-z.sgmarkets.com/sgmpinsight-notification-hub/api/v1/Registrations/notificationhub',
  },
};
