import React, { useState } from 'react';
import { SvgIcon } from '../../../../common/components/SvgIcon';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import { SgmNotification, SgmNotificationUniverse } from './notification.types';
import { NotificationItem } from './NotificationItem';
import { distributeNotifications, getServiceNotifications } from './notifications.utils';
import { useSgmNotification } from './SgmNotificationContext';

type NoNotificationProps = ITranslatorProps;

interface ListOfNotificationsProps extends ITranslatorProps {
  producerCode?: string;
  language: string;
  notifications: SgmNotification[];
}

// Default view - the user has no new notification...
export const NoNotification = ({ translator }: NoNotificationProps) => (
  <section className="pt-5 h-100 d-flex justify-content-center">
    <article className="empty-state text-center text-secondary" style={{ maxWidth: 256 }}>
      <SvgIcon type="check_circle_outline" width={32} height={32} />
      <h4>{translator.translate('notifications.emptyTitle')}</h4>
      <p>{translator.translate('notifications.emptyMessage')}</p>
    </article>
  </section>
);

type NotificationTab = 'new' | 'service';

interface NotificationsHeaderProps extends ITranslatorProps {
  activeTab: NotificationTab;
  hasServiceNotifications: boolean;
  changeTab: (tab: NotificationTab) => void;
}

const NotificationsHeader = ({
  changeTab,
  activeTab,
  hasServiceNotifications,
  translator,
}: NotificationsHeaderProps) => {
  const { dismissAllNotifications } = useSgmNotification();

  return (
    <div className="d-flex justify-content-between border-bottom px-4">
      <div className="d-flex sgwt-notifications-tabs">
        <button className={`btn-nav-link ${activeTab === 'new' ? 'active' : ''}`} onClick={() => changeTab('new')}>
          {translator.translate('notifications.tab.new')}
        </button>
        {hasServiceNotifications && (
          <button
            className={`btn-nav-link ${activeTab === 'service' ? 'active' : ''}`}
            onClick={() => changeTab('service')}
          >
            {translator.translate('notifications.tab.service')}
          </button>
        )}
      </div>
      <button onClick={dismissAllNotifications} className="btn btn-link btn-sm">
        {translator.translate('notifications.dismissAll')}
      </button>
    </div>
  );
};

interface NotificationsUniverseProps extends ITranslatorProps {
  universe?: SgmNotificationUniverse;
  multipleUniverse: boolean;
  language: string;
  notifications: SgmNotification[][];
}

const NotificationsUniverse = ({
  universe,
  language,
  translator,
  multipleUniverse,
  notifications,
}: NotificationsUniverseProps) => {
  if (notifications.length === 0) {
    return null;
  }
  return (
    <>
      {multipleUniverse && (
        <div className="text-secondary text-small fs-6 border-bottom mt-4 px-4 pb-1">
          {translator.translate(`notifications.universe.${universe}`)}
        </div>
      )}
      <ul className="list-group list-group-flush sgwt-notifications-list">
        {notifications.map((notifications: SgmNotification[]) => (
          <NotificationItem
            key={notifications[0].id}
            translator={translator}
            language={language}
            notifications={notifications}
          />
        ))}
      </ul>
    </>
  );
};

const countUniversesWithNotifications = (
  distributedNotifications: Record<SgmNotificationUniverse, SgmNotification[][]>,
) => {
  const sgmarkets = Math.max(distributedNotifications.sgMarkets.length, 1);
  const sg = Math.max(distributedNotifications.societeGenerale.length, 1);
  const sgef = Math.max(distributedNotifications.sgef.length, 1);
  return sgmarkets + sg + sgef;
};

// Default view - the user has some notifications...
export const ListOfNotifications = ({
  producerCode,
  language,
  notifications,
  translator,
}: ListOfNotificationsProps) => {
  const [tab, setTab] = useState<NotificationTab>('new');
  const distributedNotifications = distributeNotifications(notifications);
  const serviceNotifications = getServiceNotifications(notifications, producerCode);
  const hasServiceNotifications = serviceNotifications.length > 0;
  const hasMultipleUniverses = countUniversesWithNotifications(distributedNotifications) > 1;

  const changeTab = (newTab: NotificationTab) => setTab(newTab);

  return (
    <div>
      <NotificationsHeader
        translator={translator}
        activeTab={tab}
        changeTab={changeTab}
        hasServiceNotifications={hasServiceNotifications}
      />

      {tab === 'new' ? (
        <>
          <NotificationsUniverse
            universe="sgMarkets"
            language={language}
            translator={translator}
            multipleUniverse={hasMultipleUniverses}
            notifications={distributedNotifications.sgMarkets}
          />
          <NotificationsUniverse
            universe="societeGenerale"
            language={language}
            translator={translator}
            multipleUniverse={hasMultipleUniverses}
            notifications={distributedNotifications.societeGenerale}
          />
          <NotificationsUniverse
            universe="sgef"
            language={language}
            translator={translator}
            multipleUniverse={hasMultipleUniverses}
            notifications={distributedNotifications.sgef}
          />
        </>
      ) : (
        <NotificationsUniverse
          language={language}
          translator={translator}
          multipleUniverse={false}
          notifications={serviceNotifications}
        />
      )}
    </div>
  );
};
