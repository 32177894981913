import React, { createContext, ReactNode, useContext, useEffect, useReducer } from 'react';
import { HttpTransportType, HubConnection, HubConnectionState } from '@microsoft/signalr';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { registerAccountCenterEvent } from '../../../../common/monitoring';
import { getAuthorizationToken } from '../../../../common/sgwt-widgets-utils';
import { EventDetails } from '../../sgwt-account-center';
import { ISgwtAccountCenterUser } from '../../shared/sgwt-account-center.types';
import { IPartialIncident } from '../PartialIncident/PartialIncident';
import { SgmNotification } from './notification.types';
import { createInitialState, sgmNotificationsReducer } from './notifications.reducer';
import {
  createConnection,
  getNotifications,
  getPartialIncidents,
  joinGroup,
  leaveGroup,
  startHubConnection,
} from './notifications.utils';

interface SgmNotificationContextProps {
  // SGM Notifications
  notifications: SgmNotification[];
  unreadNotifications: number;
  dismissNotifications: (ids: number[]) => void;
  dismissAllNotifications: () => void;
  // Partial incidents
  incidents: IPartialIncident[];
  incidentStatus: number | null;
  cleanIncidents: () => void;
}

const defaultOptions = {
  notifications: [],
  unreadNotifications: 0,
  dismissNotifications: () => {},
  dismissAllNotifications: () => {},
  incidents: [],
  incidentStatus: null,
  cleanIncidents: () => {},
};

export const SgmNotificationContext = createContext<SgmNotificationContextProps>(defaultOptions);

interface NotificationProviderProps {
  producerCode?: string;
  user: ISgwtAccountCenterUser | null;
  widgetConfiguration: WidgetConfiguration;
  emitEvent: (name: string, detail: EventDetails) => void;
  children?: ReactNode;
}

// List of domains for which users cannot use the live notifications with Web Sockets.
const DISABLE_LIVE_NOTIFICATIONS_FOR_EMAIL_DOMAINS = ['brd.ro'];

// Some users have the WebSockets disabled / forbidden by their network configurations. For them, the live notifications
// result in an authentication popup.
// For this kind of population, we simply remove the live notification.
// cf. https://sgithub.fr.world.socgen/SG-Web-Toolkit/sgwt-widgets/issues/571
const shouldLiveNotificationsBeDisabled = (user: ISgwtAccountCenterUser) => {
  if (!user || !user.mail || !user.mail.includes('@')) {
    return false;
  }
  const domain = user.mail.split('@')[1].toLowerCase();
  return DISABLE_LIVE_NOTIFICATIONS_FOR_EMAIL_DOMAINS.indexOf(domain) > -1;
};

export const SgmNotificationProvider = ({
  producerCode,
  user,
  emitEvent,
  widgetConfiguration,
  children,
}: NotificationProviderProps) => {
  const [context, dispatch] = useReducer(sgmNotificationsReducer, null, () =>
    createInitialState({ producerCode, emitEvent, widgetConfiguration }),
  );

  // On initialization, we start the live connection and fetch the notifications and incidents.
  useEffect(
    function startEverything() {
      if (!user) {
        return;
      }

      let hubConnection: HubConnection | null;

      const fetchNotificationsAndIncidents = () => {
        console.debug('[sgwt-account-center][notification] fetching Notifications');
        getNotifications(widgetConfiguration)
          .then((notifications) => {
            dispatch({ type: 'notifications.set', notifications });
          })
          .catch((error) => {
            console.error('Failed to fetch SGM notifications', error);
            registerAccountCenterEvent('notifications.error.load-data', { message: error.message });
          });

        if (producerCode) {
          console.debug('[sgwt-account-center][notification] fetching Incidents');
          getPartialIncidents(widgetConfiguration, producerCode)
            .then((incidents) => {
              dispatch({ type: 'incidents.set', incidents });
            })
            .catch((error) => {
              console.error('Failed to fetch Partial Incidents', error);
              registerAccountCenterEvent('partial-incidents.error.load-data', { message: error.message });
            });
        }
      };

      const initLiveConnection = async () => {
        console.debug('[sgwt-account-center][notification] Initialize live connection');
        const getToken = () => {
          const currentToken = getAuthorizationToken(widgetConfiguration);
          return (currentToken || '').replace('Bearer ', '');
        };

        if (!user || shouldLiveNotificationsBeDisabled(user)) {
          registerAccountCenterEvent('notifications.disable-live-notifications');
          return;
        }
        hubConnection = createConnection(widgetConfiguration, {
          accessTokenFactory: getToken,
          transport: HttpTransportType.WebSockets,
          withCredentials: false,
        });
        await startHubConnection(widgetConfiguration, hubConnection);
        if (producerCode) {
          await joinGroup(widgetConfiguration, producerCode, hubConnection);
        }
        hubConnection.on('receiveNotification', (notification: SgmNotification) =>
          dispatch({ type: 'notifications.add', notification }),
        );
        hubConnection.on('receivePartialIncident', (incident: IPartialIncident) =>
          dispatch({ type: 'incidents.add', incident }),
        );
      };

      const destroy = () => {
        console.debug('[sgwt-account-center][notification] Destroy');
        if (hubConnection) {
          if (producerCode && hubConnection.state === HubConnectionState.Connected) {
            leaveGroup(widgetConfiguration, producerCode, hubConnection).catch((error) => {
              widgetConfiguration.error(error.toString());
            });
          }
          hubConnection.off('receiveNotification');
          hubConnection.off('receivePartialIncident');
          hubConnection.stop().catch((err) => widgetConfiguration.error(err.toString()));
        }
      };

      fetchNotificationsAndIncidents();
      initLiveConnection();

      return () => {
        if (user) {
          destroy();
        }
      };
    },
    [user, widgetConfiguration, producerCode],
  );

  const dismissNotifications = (ids: number[]) => {
    dispatch({ type: 'notifications.dismiss', ids });
  };

  const dismissAllNotifications = () => {
    dispatch({ type: 'notifications.dismiss-all' });
  };

  const cleanIncidents = () => {
    dispatch({ type: 'incidents.clean' });
  };

  const unreadNotifications = context.notifications.filter((notif: SgmNotification) => !notif.isRead).length;

  return (
    <SgmNotificationContext.Provider
      value={{
        // SGM Notifications
        notifications: context.notifications,
        dismissNotifications,
        dismissAllNotifications,
        unreadNotifications,
        // Partial Incidents
        incidents: context.incidents,
        incidentStatus: context.incidentStatus,
        cleanIncidents,
      }}
    >
      {children}
    </SgmNotificationContext.Provider>
  );
};

export function useSgmNotification() {
  return useContext(SgmNotificationContext);
}
