import React from 'react';
import { SvgIcon } from '../../../../common/components/SvgIcon';
import { registerAccountCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import { IMyServiceSearchCompany, IMyServiceSearchPerson, IMyServiceSearchSource } from './my-services.types';
import { SeeAllResults } from './MyServicesSearchPanel';

const MAX_RESULTS = 3;

interface IPersonsListProps extends ITranslatorProps {
  items: (IMyServiceSearchPerson | IMyServiceSearchCompany)[];
  environment: 'homologation' | 'production';
  searchValue: string;
  totalCount: number;
  type: 'person' | 'company';
}

export const PersonsAndCompaniesList: React.FC<IPersonsListProps> = ({
  environment,
  items,
  searchValue,
  totalCount,
  translator,
  type,
}: IPersonsListProps) => (
  <div className="mt-3">
    <p className="mb-0 pb-2 mx-n4 px-4 text-secondary font-weight-normal fw-normal">
      {translator.translate(`myServices.${type}`)}
    </p>
    <ul className="list-group mx-n4 border-bottom border-top">
      {items.slice(0, MAX_RESULTS).map((item: IMyServiceSearchPerson | IMyServiceSearchCompany, index: number) => (
        <PersonOrCompany
          key={`${type}-${item.title}`}
          firstItem={index === 0}
          item={item}
          translator={translator}
          type={type}
        />
      ))}
    </ul>

    <div className="text-right pt-2 mx-n4 px-4">
      {items.length > MAX_RESULTS && (
        <SeeAllResults
          count={totalCount}
          environment={environment}
          searchValue={searchValue}
          translator={translator}
          type={type}
        />
      )}
    </div>
  </div>
);

interface IPersonOrCompanyProps extends ITranslatorProps {
  firstItem: boolean;
  item: IMyServiceSearchPerson | IMyServiceSearchCompany;
  type: 'person' | 'company';
}

interface IPersonOrCompanyState {
  expanded: boolean;
}

class PersonOrCompany extends React.Component<IPersonOrCompanyProps, IPersonOrCompanyState> {
  private handleOutsideClickFn: (event: Event) => void;
  private element?: HTMLDivElement;
  state = { expanded: false };

  constructor(props: IPersonOrCompanyProps) {
    super(props);
    this.handleOutsideClickFn = this.handleOutsideClick.bind(this);
  }

  private clickLink = (source: string) => {
    registerAccountCenterEvent(`my-services.click-${this.props.type}.${source}`, { title: this.props.item.title });
  };

  private handleOutsideClick(event: Event) {
    if (this.state.expanded && this.element && !this.element.contains(event.target as Node)) {
      this.switchVisibility();
    }
  }

  private switchVisibility() {
    this.setState(
      {
        expanded: !this.state.expanded,
      },
      () => {
        setTimeout(() => {
          if (this.state.expanded) {
            // Now displayed, so we listen to click events to hide the Popover if the click is made outside of it.
            document.addEventListener('click', this.handleOutsideClickFn, false);
            document.addEventListener('touchend', this.handleOutsideClickFn, false);
          } else {
            // Now hidden, so we stop listening to click events.
            document.removeEventListener('click', this.handleOutsideClickFn, false);
            document.removeEventListener('touchend', this.handleOutsideClickFn, false);
          }
        });
      },
    );
  }

  render() {
    const { firstItem, item, translator, type } = this.props;

    const content = (
      <div className="d-inline-flex align-items-center text-decoration-none py-2">
        <div
          style={{
            height: '42px',
            width: '42px',
            borderRadius: '50%',
            fontSize: '1rem',
          }}
          className="flex-shrink-0 d-flex justify-content-center align-items-center border text-primary"
        >
          {type === 'person' ? (
            <span className="overflow-hidden font-weight-medium">{item.initials}</span>
          ) : (
            <SvgIcon type="business" width={26} height={26} />
          )}
        </div>
        <div className="d-inline-flex flex-column justify-content-center ml-2 ms-2">
          <span className="text-primary font-weight-medium">{item.title}</span>
          <span className="text-secondary">{item.subtitle}</span>
        </div>
      </div>
    );

    const sources = (
      <div className="d-inline-flex">
        {item.sourceInfos.map((source) => (
          <a
            key={`source-${source.initials}`}
            onClick={() => this.clickLink(source.source)}
            href={source.url}
            target="_blank"
            className="d-inline-flex d-flex justify-content-center align-items-center border text-primary ml-2  ms-2 text-decoration-none"
            style={{ height: '36px', width: '36px', borderRadius: '10px' }}
            rel="noreferrer"
          >
            {source.initials}
          </a>
        ))}
      </div>
    );

    return (
      <li className="list-group-item list-group-item-action py-0 px-4" onClick={() => this.switchVisibility()}>
        {item.sourceInfos.length === 1 ? (
          <a
            href={item.sourceInfos[0].url}
            onClick={() => this.clickLink(item.sourceInfos[0].source)}
            target="_blank"
            className={`d-flex align-items-center justify-content-between ml-n3 ms-n3 pl-3 ps-3 text-decoration-none ${
              firstItem ? '' : 'border-top'
            }`}
            rel="noreferrer"
          >
            {content}
            {sources}
          </a>
        ) : (
          <div className={firstItem ? '' : 'border-top'} role="button">
            <div className="d-flex align-items-center justify-content-between ml-n3 ms-n3 pl-3 ps-3">
              {content}
              {sources}
            </div>
            <div
              ref={(elt: HTMLDivElement) => {
                this.element = elt;
              }}
            >
              {this.state.expanded && <SourcesPopover sources={item.sourceInfos} translator={translator} />}
            </div>
          </div>
        )}
      </li>
    );
  }
}

interface ISourcesPopoverProps extends ITranslatorProps {
  sources: IMyServiceSearchSource[];
}

class SourcesPopover extends React.Component<ISourcesPopoverProps> {
  render() {
    return (
      <div className="position-absolute popover bs-popover-bottom show fade" style={{ left: '1.5rem', top: '100%' }}>
        <div className="arrow" style={{ left: 'calc(50% - 8px)' }} />
        <div className="popover-body p-0">
          {this.props.sources.map((source: IMyServiceSearchSource, index: number) => (
            <a
              key={`source-${source.initials}`}
              className={`dropdown-item ${index === 0 ? '' : 'border-top'}`}
              role="button"
              href={source.url}
              target="_blank"
              rel="noreferrer"
            >
              <span
                className="mr-2 me-2 d-inline-flex d-flex justify-content-center align-items-center all-services-initials-icon-sm bg-white border text-primary text-decoration-none"
                style={{
                  height: '36px',
                  width: '36px',
                  borderRadius: '10px',
                }}
              >
                {source.initials}
              </span>
              {this.props.translator.translate('myServices.viewIn', {
                source: `${source.source}`,
              })}
            </a>
          ))}
        </div>
      </div>
    );
  }
}
