import React from 'react';
import { RatingSelector } from '../../../../common/components/RatingSelector';
import { SvgIcon } from '../../../../common/components/SvgIcon';
import { registerAccountCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import { SgwtHelpCenterPublicFields } from '../../../sgwt-help-center/sgwt-help-center';

interface HelpCenterButtonProps extends ITranslatorProps {
  feedbackRequested: boolean;
  feedbackRequestIncludeIgnoreOption: boolean;
  onClick: () => void;
  onFeedbackClosed: () => void;
}

interface FeedbackPopoverProps extends ITranslatorProps {
  feedbackRequestIncludeIgnoreOption: boolean;
  onClose: () => void;
  onDoNotNofityClicked: () => void;
  onRatingClicked: (rating: number) => void;
}

const FeedbackPopover: React.FC<FeedbackPopoverProps> = ({
  feedbackRequestIncludeIgnoreOption,
  onClose,
  onDoNotNofityClicked,
  onRatingClicked,
  translator,
}: FeedbackPopoverProps) => (
  <div
    className="position-absolute popover bs-popover-bottom show fade"
    style={{
      left: '-100px',
      top: '40px',
      width: '240px',
      zIndex: 1024,
    }}
  >
    <div className="arrow popover-arrow position-absolute" style={{ left: '115px' }} />
    <div className="popover-body">
      <div className="d-flex justify-content-between mb-2">
        <span className="text-secondary">{translator.translate('feedback.request.title')}</span>
        <button className="btn btn-link p-0">
          <i className="sgwt-widgets-icon">
            <SvgIcon type="close" className="icon" height={16} width={16} onClick={onClose} />
          </i>
        </button>
      </div>
      <div className="font-weight-bold fw-bold">{translator.translate('feedback.request.question')}</div>
      <div className="my-2">
        <RatingSelector
          container="popover"
          isValid={true}
          ratingSelected={onRatingClicked}
          starSize={24}
          translator={translator}
        />
      </div>
      {feedbackRequestIncludeIgnoreOption && (
        <button className="btn btn-link pl-0" onClick={onDoNotNofityClicked}>
          {translator.translate('feedback.request.doNotAskAgain')}
        </button>
      )}
    </div>
  </div>
);

export const HelpCenterButton = (props: HelpCenterButtonProps) => {
  const onDoNotNofityClicked = () => {
    const helpCenter = document.querySelector<SgwtHelpCenterPublicFields & Element>('sgwt-help-center');
    if (!helpCenter || !helpCenter.stopRequestingFeedback) {
      console.error('The Help Center has not been found, or has not the Feedback capability');
    } else {
      helpCenter.stopRequestingFeedback();
    }
    registerAccountCenterEvent('feedback-popup.stop-requesting-feedback');
    props.onFeedbackClosed();
  };

  const onRatingClicked = (rating: number) => {
    const helpCenter = document.querySelector<SgwtHelpCenterPublicFields & Element>('sgwt-help-center');
    if (!helpCenter || !helpCenter.feedback) {
      console.error('The Help Center has not been found, or has not the Feedback capability');
    } else {
      helpCenter.feedback(rating);
    }
    registerAccountCenterEvent('feedback-popup.click-rating', { rating });
    props.onFeedbackClosed();
  };

  if (props.feedbackRequested) {
    registerAccountCenterEvent(`feedback-popup.open.${props.feedbackRequestIncludeIgnoreOption ? 'auto' : 'manual'}`);
  }

  return (
    <div className="position-relative">
      <button
        className="btn btn-flat-primary btn-lg sgwt-account-center-help ml-1 ms-1 ps-2 pe-2"
        onClick={props.onClick}
        aria-haspopup="true"
        aria-expanded="false"
        aria-label={props.translator.translate('button.helpCenter')}
        title={props.translator.translate('button.helpCenter')}
      >
        <SvgIcon type="help_outline" className="icon" height={24} width={24} />
      </button>
      {props.feedbackRequested && (
        <FeedbackPopover
          feedbackRequestIncludeIgnoreOption={props.feedbackRequestIncludeIgnoreOption}
          onClose={props.onFeedbackClosed}
          onDoNotNofityClicked={onDoNotNofityClicked}
          onRatingClicked={onRatingClicked}
          translator={props.translator}
        />
      )}
    </div>
  );
};
